import React from 'react';
import cn from 'classnames';
import qs from 'qs';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetStory } from 'src/hooks/get-story';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { setImagePopup } from 'src/store/ui';
import { selectConfig } from 'src/store/config/selectors';
import { getKeyFromImageUrl } from 'src/utils/get-key-from-image-url';
import { getCroppedPhoto } from 'src/utils/get-cropped-photo';
import { imgixUrl } from 'src/utils/imgix-url';
import { QS_STRINGIFY_OPTIONS } from '@api/constants/other';
import { ROOT } from 'src/constants/routes';

import { ImageCredit } from 'src/components/common/ImageCredit';

import css from './SubmissionWrapper.module.scss';

export default function SubmissonWrapper() {
  const navigate = useNavigate();
  const params = useParams<{ personId: string; memoryId: string }>();
  const config = useAppSelector(selectConfig);
  const personData = config.people.find((person) => {
    if (!params.personId) return null;
    return person.id === params.personId.toString();
  });
  const personName = personData ? `${personData.b} ${personData.c}` : '';
  const memoryData = useGetStory({ id: params.memoryId });
  const dispatch = useAppDispatch();
  const TEXTS = useAppSelector(selectConfig).texts.website.main;

  const cropped = memoryData[0]?.memory?.photo
    ? getCroppedPhoto(memoryData[0].memory?.photo, 'mainPhoto', memoryData[0].memory?.metadata)
    : memoryData[0]?.memory?.photos?.[0]?.url;

  const imgixParams = {
    w: 330,
    h: 207,
    auto: 'format,compress',
    ...(!memoryData[0]?.disableFaceRecognition && { fit: 'facearea,crop', facepad: 100 }),
  };

  let imgUrl = imgixUrl(
    cropped || memoryData[0]?.memory?.photo || memoryData[0]?.memory?.photos?.[0]?.url,
    imgixParams,
  );

  if (memoryData[0]?.memory?.photos) {
    if (memoryData[0]?.memory?.photo && memoryData[0]?.memory.metadata?.hidePhoto) {
      const hide = memoryData[0]?.memory.metadata?.hidePhoto.find((photo) => photo === memoryData[0]?.memory?.photo);

      if (hide) imgUrl = '';
    }
  }

  const imageCredit =
    memoryData[0]?.memory?.metadata?.imageCredit?.[
      getKeyFromImageUrl(
        memoryData[0].memory?.photo ||
          memoryData[0].memory?.photos?.[0]?.url ||
          memoryData[0].memory?.photosUploadedByAdmin?.[0]?.url ||
          '',
      )
    ] ?? '';

  const onImageClick = () => {
    dispatch(setImagePopup(imgUrl || ''));
  };

  const clickOnBackBtn = () => {
    const queryData = {
      filters: { person: params.personId },
      page: 1,
      query: undefined,
    };

    const queryStr = qs.stringify(queryData, QS_STRINGIFY_OPTIONS);
    navigate(`${ROOT}?${queryStr}`);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.item}>
        <div className={cn(css.photo)} onClick={onImageClick}>
          <img
            style={process.env.ENV !== 'production' ? { objectFit: 'cover' } : undefined}
            src={imgUrl}
            alt={personName}
          />
          {imageCredit && <ImageCredit textAlign="right" className={css['image-credit']} text={imageCredit} />}
        </div>
        <div className={css.senderInfo}>
          {TEXTS.list.listItem.sender}: {memoryData[0]?.contact.name}
        </div>
        <div className={css.subtitle}>
          <span dangerouslySetInnerHTML={{ __html: memoryData[0]?.memory?.story || '' }} />
        </div>
      </div>
      <div className={css.backBtn} onClick={clickOnBackBtn}>
        לקיר המלא של החלל &gt;
      </div>
    </div>
  );
}
