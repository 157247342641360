import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetStory } from 'src/hooks/get-story';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { selectImagePopupState } from 'src/store/ui/selectors';

import { Header } from 'src/components/common/Header';
import { Footer } from 'src/components/common/Footer';
import { FormSection } from 'src/components/pages/Main/FormSection';
import Head from 'src/components/common/Head';

import TopSection from './TopSection';
import SubmissonWrapper from './SubmissionWrapper';
import ImagePopup from '../../common/ImagePopup';

import css from './Memory.module.scss';

export default function Memory() {
  const params = useParams<{ personId: string; memoryId: string }>();
  const memoryData = useGetStory({ id: params.memoryId });

  const imageUrl = useAppSelector(selectImagePopupState);
  const { seo, og } = useAppSelector(selectConfig).seo_og;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head
        seo={{
          title: memoryData[0]?.contact.name,
          description: memoryData[0]?.memory?.story?.replace('<p>', '').replace('</p>', '') || '',
        }}
        og={{
          title: memoryData[0]?.contact.name,
          description: memoryData[0]?.memory?.story?.replace('<p>', '').replace('</p>', '') || '',
          imageUrl: og.mainPage.imageUrl,
        }}
      ></Head>
      <div className={css.memoryWrapper}>
        <Header />
        <TopSection />
        <SubmissonWrapper />
        <Footer />
        <ImagePopup src={imageUrl} />
      </div>
    </>
  );
}
