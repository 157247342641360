import React from 'react';
import cn from 'classnames';
import { useAppDispatch } from 'src/hooks/store';
import { setImagePopup } from 'src/store/ui';

import css from './ImagePopup.module.scss';

interface Props {
  src: string | null;
}

export default function ImagePopup({ src }: Props) {
  const dispatch = useAppDispatch();

  const closeClickListener = () => {
    dispatch(setImagePopup(null));
  };
  return (
    <div className={cn(css.imagePopup, src && css.show)}>
      <div className={css.overlay} onClick={closeClickListener}></div>
      <div className={css.popupWrapper}>
        <div className={css.closeBtn} onClick={closeClickListener}></div>
        <div className={css.image} style={{ backgroundImage: src ? `url(${src})` : '' }}></div>
      </div>
    </div>
  );
}
