import React, { useState } from 'react';
import qs from 'qs';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import { share, ShareType } from 'src/utils/share';

import Icon from 'src/components/common/Icon';
import { SHARE_CONSTANTS } from 'src/constants/other';
import { MEMORY_ROUTE } from 'src/constants/routes';

import { QS_STRINGIFY_OPTIONS } from '@api/constants/other';

import css from './ListItemSocialls.module.scss';

interface Props {
  personId: string;
  memoryId?: string;
  title?: string;
  description?: string;
  className?: string;
  isOnMain?: boolean;
}
export default function ListItemSocialls({
  personId,
  memoryId,
  title,
  description,
  className = '',
  isOnMain = false,
}: Props) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const clickOnShare = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const id = e.currentTarget.dataset.id as ShareType;
    let domainName = '';
    if (!id) return;
    switch (process.env.ENV) {
      case 'production':
        domainName = `https://memorieswall.n12.co.il/${personId}/memory/${memoryId}`;
        break;

      case 'development':
        domainName = `https://website-mako-n12-7-10-memory-wall-dev.firebaseapp.com/${personId}/memory/${memoryId}`;
        break;

      case 'local':
        domainName = `http://localhost:${process.env.WEBSITE_DEV_PORT}/${personId}/memory/${memoryId}`;
        break;

      default:
        break;
    }
    if (id === 'Facebook' || id === 'Twitter' || id === 'whatsApp') {
      share({ type: id, ogTitle: title, ogDescription: description, domainName });
    }
  };

  return (
    <div className={cn(css.sociallsWrapper, isOnMain && css.forMain, className)}>
      <div className={css.sharesMenuBtn}>
        <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
          <Icon type="shares-menu" />
        </button>
      </div>
      <div className={cn(css.sharesMenu, isOpenMenu && css.active)}>
        <Link
          to={MEMORY_ROUTE.replace(':personId', personId).replace(':memoryId', memoryId || '')}
          data-id={SHARE_CONSTANTS.WHATS_APP}
          onClick={clickOnShare}
          className={css.shareLink}
        >
          <Icon type="inner-whatsapp" />
        </Link>
        <Link
          to={MEMORY_ROUTE.replace(':personId', personId).replace(':memoryId', memoryId || '')}
          data-id={SHARE_CONSTANTS.TWITTER}
          onClick={clickOnShare}
          className={css.shareLink}
        >
          <Icon type="inner-twitter" />
        </Link>
        <Link
          to={MEMORY_ROUTE.replace(':personId', personId).replace(':memoryId', memoryId || '')}
          data-id={SHARE_CONSTANTS.FACEBOOK}
          onClick={clickOnShare}
          className={css.shareLink}
        >
          <Icon type="inner-facebook" />
        </Link>
      </div>
    </div>
  );
}
