import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UiState = {
  popupImageUrl: string | null;
};

const initialState: UiState = {
  popupImageUrl: null,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setImagePopup: (state, action: PayloadAction<UiState['popupImageUrl']>) => {
      state.popupImageUrl = action.payload;
    },
  },
});

export const { setImagePopup } = ui.actions;
export default ui.reducer;
