import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFormik, useFormikContext, FormikContext, FormikContextType } from 'formik';
import qs from 'qs';

import { QS_STRINGIFY_OPTIONS, PUBLIC_SUBMISSIONS_PAGE_SIZE } from '@api/constants/other';

import { ROOT } from 'src/constants/routes';
import { analytics } from 'src/utils/analytics';
import { CAN_USE_DOM } from 'src/constants/other';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { submissions as submissionsApi, ApiResponse, PaginateResult } from 'src/store/api/submissions';
import { useResponsive } from 'src/hooks/responsive';

import { formValues2QueryData, queryString2FormValues } from './helpers';
import { FormValues } from './types';

interface ISearchContext {
  formik: FormikContextType<FormValues>;
  isFilterActive: boolean;
  isSearchActive: boolean;
  isFetching: boolean;
  result?: ApiResponse<PaginateResult>;
}

export const PAGE_SIZE = PUBLIC_SUBMISSIONS_PAGE_SIZE;
export const MOBILE_PAGE_SIZE = 20;
const SearchContext = React.createContext<ISearchContext | null>(null);

const SearchContextProvider = (props: React.PropsWithChildren) => {
  const location = useLocation();
  const config = useAppSelector(selectConfig);
  const formik = useFormikContext<FormValues>();
  const [isFilterActive, setIsFilterActive] = React.useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = React.useState<boolean>(false);
  const [isMobile] = useResponsive('MOBILE');

  const DEVICE_PAGE_SIZE = isMobile ? MOBILE_PAGE_SIZE : PAGE_SIZE;

  const [queryData, setQueryData] = React.useState<ReturnType<typeof formValues2QueryData> | null>(
    formValues2QueryData(queryString2FormValues(CAN_USE_DOM ? location.search : '', config)),
  );

  const { data, isFetching } = submissionsApi.endpoints.paginateSubmissions.useQuery({
    query: queryData?.query,
    filters: { ...(queryData?.filters || {}) },
    limit: DEVICE_PAGE_SIZE,
    offset: (formik.values.page - 1) * DEVICE_PAGE_SIZE,
  });

  const [contextValue, setContextValue] = React.useState<ISearchContext | null>({
    formik,
    isFilterActive: false,
    isSearchActive: false,
    isFetching,
    result: data,
  });

  React.useEffect(() => {
    const formValues = queryString2FormValues(location.search, config);
    const isFilterActive = false;
    const isSearchActive = formValues.query !== '' || isFilterActive;

    formik.setValues(formValues);

    setQueryData(formValues2QueryData(formValues));
    setIsFilterActive(isFilterActive);
    setIsSearchActive(isSearchActive);
  }, [location.search]);

  React.useEffect(() => {
    setContextValue({
      formik,
      isFilterActive,
      isSearchActive,
      isFetching,
      result: data,
    });
  }, [formik, data, isFetching, isFilterActive, isSearchActive]);

  return <SearchContext.Provider value={contextValue}>{props.children}</SearchContext.Provider>;
};

export const useSearch = () => {
  const context = React.useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within SearchContextProvider');
  }

  return context;
};

export default (props: React.PropsWithChildren) => {
  const location = useLocation();
  const config = useAppSelector(selectConfig);
  const navigate = useNavigate();
  const formik = useFormik<FormValues>({
    initialValues: queryString2FormValues(location.search, config),
    onSubmit: (values) => {
      const queryData = formValues2QueryData(values);
      console.log('queryData', queryData);

      const queryStr = qs.stringify(queryData, QS_STRINGIFY_OPTIONS);

      navigate(`${ROOT}?${queryStr}`);
    },
  });

  return (
    <FormikContext.Provider value={formik}>
      <SearchContextProvider>{props.children}</SearchContextProvider>
    </FormikContext.Provider>
  );
};
